/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import BarLoader from 'react-spinners/BarLoader';
import { contentHTMLGenerator } from '../../utils/componentGeneration';
import { useAuthContext } from '../../utils/AuthContext';
import { courseActive, findBiggestPromotion } from '../../utils/dataManip';
import { toastError } from '../../utils/toastManager';

import { Checkout, processTransaction } from './Checkout';
import { TestimonialDisplay, TestimonialForm } from './Testimonial';
import SocialMediaButtons from '../../utils/SocialMediaButtons';

const initialOptions = {
  'client-id': process.env.REACT_APP_PAYPAL_ID,
  currency: 'EUR',
  intent: 'capture',
};

function Course() {
  const { auth } = useAuthContext();
  const { courseId } = useParams();

  const [transactions, setTransactions] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [course, setCourses] = useState();
  const [instructors, setInstructors] = useState([]);
  const [promotion, setPromotion] = useState();

  const [showPaymentPortal, setShowPaymentPortal] = useState(false);
  const [processingOrder, setProcessingOrder] = useState(false);

  async function toggleEnroll() {
    if (!auth || !auth.currentUser) {
      toastError('Please log in to Enroll');
      return;
    }

    setShowPaymentPortal((prev) => {
      if (!prev) window.scrollTo(0, 0);
      return !prev;
    });
  }

  async function freeEnroll() {
    if (!auth || !auth.currentUser) {
      toastError('Please log in to Enroll');
      return;
    }
    if (processingOrder) return;
    processTransaction(course, auth, promotion, setProcessingOrder);
  }

  async function fetchData() {
    // fetch courses
    await fetch(`/.netlify/functions/courses?ids=${courseId}`)
      .then((response) => response.json())
      .then(async (responseCourse) => {
        if (responseCourse.length > 0) {
          setCourses(responseCourse[0]);
          const instructorIds = responseCourse[0]?.instructors
            .map((id) => `ids=${id}`)
            .join('&');

          // fetch instructors
          await fetch(
            `/.netlify/functions/instructors?${instructorIds}&fields=displayName&fields=coverImageURL&fields=bio`,
          )
            .then((response) => response.json())
            .then((response) => {
              setInstructors(response);
            });

          // fetch lessons
          await fetch(
            `/.netlify/functions/lessons?course=${courseId}&fields=title&fields=free`,
          ).then((response) => {
            response.json().then((responseJSON) => setLessons(responseJSON));
          });

          // fetch promotions
          await fetch(`/.netlify/functions/promotions?courseIds=${courseId}`)
            .then((response) => response.json())
            .then(async (responsePromotions) => {
              // only keep biggest promotion within date
              const biggestPromotion = findBiggestPromotion(
                responsePromotions,
                responseCourse[0],
              );
              if (biggestPromotion) {
                setPromotion({
                  _id: biggestPromotion._id,
                  cost: Math.floor(
                    biggestPromotion.type === 'percentage'
                      ? responseCourse[0].cost *
                          (1 - biggestPromotion.value / 100)
                      : Math.max(
                          0,
                          responseCourse[0].cost - biggestPromotion.value,
                        ),
                  ),
                  note: biggestPromotion.note,
                  startDate: biggestPromotion.startDate,
                  endDate: biggestPromotion.endDate,
                });
              }
            });
        }
      });

    // fetch transactions
    if (!auth || !auth.currentUser) return;
    await fetch(
      `/.netlify/functions/transactions?email=${auth.currentUser.email}&payload=${auth.currentUser.uid}`,
    )
      .then((response) => response.json())
      .then((responseJSON) => setTransactions(responseJSON));
  }
  useEffect(() => {
    fetchData();
  }, [auth?.currentUser]);
  useEffect(() => {
    document.title = course?.title;
  }, [course]);

  if (!course) {
    return (
      <div className="flex justify-center">
        <BarLoader color="#023059" className="m-4" loading />
      </div>
    );
  }
  return (
    <div>
      {/* HEADER SECTION */}
      <div
        className={`
          sticky top-14 z-40 flex w-full items-center justify-center bg-white/80 py-2

          max-sm:flex-col
        `}
      >
        <h1 className="w-full text-center text-3xl">{course?.title}</h1>
        {!courseActive(course, transactions) ? (
          <button
            onClick={
              (promotion ? promotion.cost === 0 : course?.cost === 0)
                ? freeEnroll
                : toggleEnroll
            }
            className={`
              mx-2 w-36 w-64 rounded

              ${promotion ? 'bg-color-5' : 'bg-color-1'}

              p-2 font-ia text-lg text-white transition-all ease-in-out

              hover:-translate-y-1

              ${
                promotion
                  ? 'hover:shadow-card-small-highlight'
                  : 'hover:shadow-card-small'
              }

              max-sm:p-1
            `}
            type="button"
          >
            {processingOrder ? (
              <BarLoader color="#ffffff" className="mx-auto my-4" loading />
            ) : (
              <>
                <p>
                  <span>{promotion ? `${promotion.note} ` : 'Enroll '}</span>
                  <span
                    className={`
                      ${promotion ? 'line-through opacity-50' : ''}
                    `}
                  >
                    {`${course?.cost === 0 ? ' Free' : ` €${course?.cost}`}`}
                  </span>
                  {promotion && (
                    <span>
                      {`${
                        promotion?.cost === 0 ? ' Free' : ` €${promotion?.cost}`
                      }`}
                    </span>
                  )}
                </p>
                <p className="capitalize">
                  {`for ${course?.duration?.value} ${course?.duration?.type}${
                    course?.duration?.value > 1 ? 's' : ''
                  }`}
                </p>
              </>
            )}
          </button>
        ) : (
          <div />
        )}
      </div>

      {/* SOCIAL MEDIA TESTIMONIAL SECTION */}
      <div className="w-full bg-color-1 py-2 text-white">
        <div className="mx-auto flex max-w-2xl justify-between gap-1 px-2">
          {courseActive(course, transactions) ? (
            <TestimonialForm user={auth.currentUser} courseId={courseId} />
          ) : (
            <div />
          )}
          <div className="flex gap-1">
            <SocialMediaButtons title={course?.title} />
          </div>
        </div>
        <div className="flex justify-center pt-2">
          <TestimonialDisplay />
        </div>
      </div>

      {/* PAYMENT PORTAL SECTION */}
      {!courseActive(course, transactions) && (
        <div
          className={`
            ${showPaymentPortal ? 'pt-4' : 'max-h-0 overflow-hidden'}

            transition-all ease-in-out
          `}
        >
          <div className="flex justify-center">
            <PayPalScriptProvider options={initialOptions}>
              <Checkout
                course={course}
                auth={auth}
                promotion={promotion}
                setProcessingOrder={setProcessingOrder}
              />
            </PayPalScriptProvider>
          </div>
        </div>
      )}

      {/* COURSE CONTENT SECTION */}
      {contentHTMLGenerator(course?.content)}

      {/* LESSONS SECTION */}
      <h1 className="mb-2 mt-4 w-full text-center text-3xl">Lessons</h1>
      <div
        className={`
          flex flex-wrap justify-center gap-4 bg-color-4 px-[10%] py-8 text-white shadow-section
        `}
      >
        {course?.lessons?.map((lessonId) => {
          if (
            // eslint-disable-next-line operator-linebreak
            lessons.filter((lesson) => lesson._id === lessonId)[0]?.free ||
            courseActive(course, transactions)
          ) {
            return (
              <Link
                to={`/lessons/${courseId}/${lessonId}`}
                className={`
                  flex rounded border-2 border-white bg-color-2 p-2 transition-all ease-in-out

                  hover:-translate-y-1 hover:shadow-card-small
                `}
              >
                {lessons.filter((lesson) => lesson._id === lessonId)[0]?.title}
              </Link>
            );
          }
          return (
            <button
              type="button"
              onClick={() => {
                toastError('Please Enroll to View');
              }}
              className={`
                flex rounded border-2 border-gray-300 bg-color-1 p-2 text-gray-300 transition-all
                ease-in-out

                hover:-translate-y-1 hover:shadow-card-small
              `}
            >
              {lessons.filter((lesson) => lesson._id === lessonId)[0]?.title}
            </button>
          );
        })}
      </div>

      {/* INSTRUCTOR SECTION */}
      <h1 className="mb-2 mt-4 w-full text-center text-3xl">
        {`${instructors.length > 1 ? 'Instructors' : 'Instructor'}`}
      </h1>
      {instructors.map((instructor) => (
        <div className="flex w-full flex-col items-center bg-color-2 px-[10%] pb-4 pt-8 text-white">
          <Link
            className="group flex flex-col items-center"
            to={`/instructors/${instructor._id}`}
          >
            <img
              className={`
                w-64 rounded border-2 border-white transition-all ease-in-out

                group-hover:-translate-y-2 group-hover:shadow-card
              `}
              src={`${instructor.coverImageURL}/m/240x240`}
              alt="instructor-cover"
            />
            <h2
              className={`
                group-mb-2 mt-4 w-full text-center text-2xl text-white transition-all ease-in-out

                group-hover:text-color-5
              `}
            >
              {instructor.displayName}
            </h2>
          </Link>
          {instructor.bio.map((text) => (
            <div className="w-full bg-color-2 py-4 text-white">
              <p className="mx-auto max-w-2xl px-2">{text}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Course;
